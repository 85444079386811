import * as React from "react"
import Icon1 from "../assets/img/icons/goal.svg"
import Icon2 from "../assets/img/icons/left-click.svg"
import Icon3 from "../assets/img/icons/credit-control.svg"
import Icon4 from "../assets/img/icons/web-advertising.svg"
import Icon5 from "../assets/img/icons/crowd.svg"
import Icon6 from "../assets/img/icons/transaction.svg"
import Icon7 from "../assets/img/icons/content.svg"
import Icon8 from "../assets/img/icons/smartphone-approve.svg"
import Icon9 from "../assets/img/icons/performance-macbook.svg"
import Icon10 from "../assets/img/icons/web-accessibility.svg"
import Icon11 from "../assets/img/icons/robot-2.svg"
import Icon12 from "../assets/img/icons/user.svg"
import Icon13 from "../assets/img/icons/goal.svg"
import Icon14 from "../assets/img/icons/credit-control.svg"
import Icon15 from "../assets/img/icons/web-advertising.svg"
import Icon16 from "../assets/img/icons/crowd.svg"
import Icon17 from "../assets/img/icons/transaction.svg"
import Icon18 from "../assets/img/icons/megaphone.svg"
import Introduction from "../components/intro"
import SubNav from "../components/subNav"
import GetInTouch from "../components/getInTouch"
import Artwork from "../assets/img/artwork/artwork-05.svg"

export default function Page() {
    return (
        <div className="service-page">
            <Introduction Artwork={Artwork}>
                <h1><span>Digital</span> Marketing.</h1>
                <p className="text-larger"><strong>We strategise, create and run digital marketing campaigns across various platforms.</strong></p>
                <p>Unlike TV or print, digital marketing offers a uniquely personalised experience, targeted to users who are already searching for your product or service and is far more cost effective.</p>
            </Introduction>

            <SubNav
                title="Digital Marketing"
                nav={['Pay Per Click', 'SEO', 'Social Media Advertising', 'Pricing Structure', 'Get in Touch']}
            />

            {/* Pay Per Click */}
            <div id="features" className="component top-gradient-darker">
                <div className="container">
                    <h2 id="pay-per-click" className="text-center">Pay Per Click<span className="symbol symbol-primary"><span></span></span></h2>
                    <p className="text-center text-larger">PPC ads put your brand at the <strong>top of the search results</strong> for relevant queries to your brand and audience.<br />This valuable advertising real estate can provide an immediate source of targeted traffic to your website.<br />We work with our clients to achieve their goals, identifying their target audience and keywords, and how to best reach them.</p>

                    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-6">
                        <div className="mt-8">
                            <Icon1 className="icon-white" />
                            <h3 className="cl-primary mt-5">Targeted</h3>
                            <p>We can customise your ads according to your specified locations, time of day to show ads and where your ads are place across the Google network.</p>
                        </div>
                        <div className="mt-8">
                            <Icon2 className="icon-white" />
                            <h3 className="cl-primary mt-5">Clicks</h3>
                            <p>Only pay when you ads are clicked, no cost for appearing in the search results.</p>
                        </div>
                        <div className="mt-8">
                            <Icon3 className="icon-white" />
                            <h3 className="cl-primary mt-5">Budget Contol</h3>
                            <p>Set a daily, weekly or monthly budget for your ad spend, we will monitor and adjust campaigns to ensure your budget is not exceeded.</p>
                        </div>
                        <div className="mt-8">
                            <Icon4 className="icon-white" />
                            <h3 className="cl-primary mt-5">Customer Focused</h3>
                            <p>Your ads will reach the right people at the right time. Turning visitors into customers.</p>
                        </div>
                        <div className="mt-8">
                            <Icon5 className="icon-white" />
                            <h3 className="cl-primary mt-5">Unique Audience Targeting</h3>
                            <p>Want to only advertise to a certain demographic? We can target searchers based on their age, gender, interests and habits.</p>
                        </div>
                        <div className="mt-8">
                            <Icon6 className="icon-white" />
                            <h3 className="cl-primary mt-5">Flexibility</h3>
                            <p>Any changes to ads or campaigns can be action immediately, no waiting periods.</p>
                        </div>
                    </div>

                    <p className="text-larger text-center mt-10">Our PPC services includes set up of the Google Ads account, maintenance, keyword research and monthly reporting.</p>
                    <p className="text-center mt-10"><a href="mailto:nicola@artificial.studio" className="btn btn-green btn-lg">Get a Quote</a></p>

                </div>
            </div>

            {/* SEO */}
            <div className="component bg-darker gradient-left-blue">
                <div className="container">
                    <h2 id="seo" className="text-center">SEO<span className="symbol symbol-blue"><span></span></span></h2>
                    <p className="text-center text-larger">SEO is the process of obtaining a <strong>higher organic position</strong> in the search engine results pages.<br />We optimise your website and content to get your business noticed.<br />A SEO Audit will be performed on your site to identify any issues that are preventing your site from ranking higher on Google, advise on keywords to use for content and identify any painpoints that are prevent your visitors from converting.</p>
                    <h3 className="text-center font-normal cl-white mt-16">Some factors that influence your SEO:</h3>

                    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-6">
                        <div className="mt-8">
                            <Icon7 className="icon-white" />
                            <h3 className="cl-primary mt-5">Content</h3>
                            <p>This huge role in how Google sees your site. Ensure that your content is fresh, relevant, unique and accurate. As well as also being loaded in a logical order, as this also helps your visitors find related content easily.</p>
                        </div>
                        <div className="mt-8">
                            <Icon8 className="icon-white" />
                            <h3 className="cl-primary mt-5">Mobile Friendliness</h3>
                            <p>With over 50% of all internet searches being performed on mobile, you don't want to miss out! Make sure your website is optimised to work on these devices. Google favours mobile friendly sites.</p>
                        </div>
                        <div className="mt-8">
                            <Icon9 className="icon-white" />
                            <h3 className="cl-primary mt-5">Page Speed &amp; Performance</h3>
                            <p>We all know how quickly we loose interest in a website that is slow to load, ensure that your website remains as fast a possible.</p>
                        </div>
                        <div className="mt-8">
                            <Icon10 className="icon-white" />
                            <h3 className="cl-primary mt-5">Accessibility</h3>
                            <p>Your website needs to be accessible for everyone. Perhaps they are on a moving train, have poor internet connectivity, can't play loud audio or are using screen-readers.</p>
                        </div>
                        <div className="mt-8">
                            <Icon11 className="icon-white" />
                            <h3 className="cl-primary mt-5">Technical Factors</h3>
                            <p>These include your sitemap, robots file, image alt text, and https certificates, to name a few.</p>
                        </div>
                        <div className="mt-8">
                            <Icon12 className="icon-white" />
                            <h3 className="cl-primary mt-5">User Experience</h3>
                            <p>Is your website easy to navigate? If not, you might be losing out on potential customers. No one wants to spend ages trying to find a page or product.</p>
                        </div>
                    </div>

                    <p className="text-larger text-center mt-10">Our SEO services include maintaining good technical site health, researching keywords and phrases, and monthly reporting on your website performance.</p>
                    <p className="text-center mt-10"><a href="mailto:nicola@artificial.studio" className="btn btn-green btn-lg">Get a Quote</a></p>

                </div>
            </div>

            {/* Social Media Advertising */}
            <div className="component bg-blue gradient-right-green">
                <div className="container">
                    <h2 id="social-media-advertising" className="text-center">Social Media Advertising<span className="symbol symbol-dark"><span></span></span></h2>

                    <p className="text-center text-larger">We can <strong>target both platforms with one advert</strong>.<br />Social Media Advertising will boost your brand awareness, and quickly convert followers, into customers.</p>

                    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-6">
                        <div className="mt-8">
                            <Icon13 className="icon-white" />
                            <h3 className="cl-darker mt-5">Targeted</h3>
                            <p>We can customise your ads according to your specified locations, time of day to show ads and where your ads are place across social networks.</p>
                        </div>
                        <div className="mt-8">
                            <Icon14 className="icon-white" />
                            <h3 className="cl-darker mt-5">Budget Contol</h3>
                            <p>Set a daily, weekly or monthly budget for your ad spend, we will monitor and adjust campaigns to ensure your budget is not exceeded.</p>
                        </div>
                        <div className="mt-8">
                            <Icon15 className="icon-white" />
                            <h3 className="cl-darker mt-5">Customer Focused</h3>
                            <p>Your ads will reach the right people at the right time. Turning visitors into customers.</p>
                        </div>
                        <div className="mt-8">
                            <Icon16 className="icon-white" />
                            <h3 className="cl-darker mt-5">Unique Audience Targeting</h3>
                            <p>Want to only advertise to a certain demographic? We can target searchers based on their age, gender, interests and habits.</p>
                        </div>
                        <div className="mt-8">
                            <Icon17 className="icon-white" />
                            <h3 className="cl-darker mt-5">Flexibility</h3>
                            <p>Need to pause a campaign? Make changes to ad copy? These are easily actionable.</p>
                        </div>
                        <div className="mt-8">
                            <Icon18 className="icon-white" />
                            <h3 className="cl-darker mt-5">Cross Platform</h3>
                            <p>Advertise on Facebook and Instagram from one ad account.</p>
                        </div>
                    </div>

                    <p className="text-larger text-center mt-10">Our Social Media Advertising service includes setup of business pages and ad manager, paid advertising, monitoring and reporting on ads, as well as page audits.</p>
                    <p className="text-center mt-10"><a href="mailto:nicola@artificial.studio" className="btn btn-dark btn-lg">Get a Quote</a></p>
                </div>
            </div>

            {/* Pricing Structure */}
            <div className="component bg-dark">
                <div className="container">
                    <h3 id="pricing-structure">Pricing Structure</h3>
                    <p className="cl-grey">We cater to all different kinds of websites and budgets. Our fees are structured on an hourly rate. The amount of hours billed will depend on how many campaigns and ads are being managed.</p>
                    <p className="cl-grey">The first few months will require more hours due to the setup of ad accounts (where necessary), research needed to find the best possible keywords for your brand and audience, as well as making any necessary changes.</p>
                    <p className="cl-grey">Google and Facebook requires payment for ad spend paid directly to them. We will set up your payment details when creating your advertising account.</p>
                </div>
            </div>

            {/* Get in Touch */}
            <GetInTouch className="mt-0" />

        </div>

    )
}
