import React from "react"
import ContactForm from "../components/contactForm"
//import MyLink from "../components/link"

export default function GetInTouch({ className }) {
    return (
        <div id="get-in-touch" className={`component double-padding vh-100 mt-12 gradient-left-purple pb-32 ${className}`}>
            <h2 className="text-center mb-0">Get in Touch<span className="symbol symbol-purple"><span></span></span></h2>
            <div className="container flex flex-wrap pt-12 justify-center">
                <div className="w-full md:w-4/12">
                    <ContactForm
                        title=""
                        className="text-center"
                        description='<a href="mailto:info@artificial.studio">Email us</a> or complete the form below<br />and we’ll get back to you ASAP.'
                    />
                </div>
                {/*
                <div className="w-full md:w-2/12">
                    <div className="dashed-vertical"></div>
                </div>
                <div className="w-full md:w-4/12">
                    <div className="card">
                        <h3 className="cl-green">Need a more reliable hosting solution?</h3>
                        <p><MyLink to="/hosting-services/" className="btn">View our hosting packages</MyLink></p>
                    </div>
                    <div className="card mt-8">
                        <h3 className="cl-green">Brand new website?</h3>
                        <p><MyLink to="/domain-registration-and-management/" className="btn">Find the perfect domain</MyLink></p>
                    </div>
                </div>
                */}
            </div>
            <div className="top-gradient-darker"></div>
        </div>
    )
}